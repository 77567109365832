import {
  ModalBody,
  ModalPanel,
} from '../../../../../design-system/organisms/Modal.tsx';
import { TextInsertIcon, ThumbsDownIcon, ThumbsUpIcon, TrashIcon } from 'icons';
import styles from './AiContentModal.module.scss';
import BlockLayout from '../../../../../design-system/zeck/BlockLayout.tsx';
import { renderGroupListRenderables } from '../../../editor/blockPreprocessing/groupListRenderables.tsx';
import { Block } from 'editor-content/Block.ts';
import BlockViewable from '../../../previewAndPublish/BlockViewable.tsx';
import { getSpacing } from '../../../editor/blockPreprocessing/addSpacing.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import { useRef, useState } from 'react';
import cx from 'classnames';
import zeckStyles from '../../../../../design-system/zeck/ZeckStyles.module.scss';
import Button from '../../../../../design-system/atoms/Button.tsx';
import LinkTooltips from '../../../links/LinkTooltips/LinkTooltips.tsx';
import AiModalHeader from './AiModalHeader.tsx';
import WithTooltip from '../../../../../design-system/organisms/WithTooltip.tsx';
import useApi from '../../../../../api/useApi.ts';
import { logError } from '../../../../../logging/logger.ts';
import useLinkTooltips from '../../../links/LinkTooltips/useLinkTooltips.js';

type AiContentModalProps = {
  content: Block[];
  onInsertAbove: (content: Block[]) => void;
  onClose: () => void;
  linkables: Linkable[];
  jobId: string;
  userId: string;
  companyId: string;
  sectionId: string;
};

const AiContentModal: React.FC<AiContentModalProps> = ({
  content,
  onInsertAbove,
  onClose,
  linkables,
  jobId,
  userId,
  companyId,
  sectionId,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const zeckPrevoteCapability = null;
  const zeckFinalizeVoteCapability = null;
  const [rating, setRating] = useState<'thumbs-up' | 'thumbs-down' | null>(
    null,
  );

  const { addZeckEvent } = useApi();
  const recordEvent = async (eventType: 'AI_INSERT' | 'AI_REJECT') => {
    const maybeRating = rating
      ? addZeckEvent({
          userId: userId,
          companyId: companyId,
          contentType: 'SECTION',
          contentTypeId: sectionId,
          eventType: 'AI_RATING',
          payload: {
            positive: rating === 'thumbs-up',
            jobId,
          },
          firedAt: new Date().getTime(),
        })
      : null;

    await Promise.allSettled(
      [
        addZeckEvent({
          userId: userId,
          companyId: companyId,
          contentType: 'SECTION',
          contentTypeId: sectionId,
          eventType,
          payload: {
            jobId,
            inserted: eventType === 'AI_INSERT',
          },
          firedAt: new Date().getTime(),
        }),
        maybeRating,
      ].filter((i) => i),
    ).then((results) => {
      results.forEach((result) => {
        if (result.status === 'rejected') {
          logError(result.reason as Error);
        }
      });
    });
  };

  const linkTooltipBehavior = useLinkTooltips({
    linkables: linkables,
  });

  return (
    <>
      <ModalPanel className={styles.modalPanel}>
        <AiModalHeader>
          <span className={styles.aiModalHeader}>
            <span className={styles.secondaryTitle}>
              is finished. AI responses are experimental. Be sure to check for
              accuracy.
            </span>
            <span className={styles.howDidWeDo}>
              <WithTooltip<HTMLSpanElement> text="Zeck AI is still in beta, so please use the thumbs to help us improve.">
                {(ref, listenters) => (
                  <span ref={ref} {...listenters}>
                    How’d We Do?
                  </span>
                )}
              </WithTooltip>

              <span className={styles.howDidWeDoRating}>
                <ThumbsUpIcon
                  data-testid="thumbs-up"
                  checked={rating === 'thumbs-up'}
                  onClick={() =>
                    setRating((prev) =>
                      prev === 'thumbs-up' ? null : 'thumbs-up',
                    )
                  }
                />

                <ThumbsDownIcon
                  checked={rating === 'thumbs-down'}
                  onClick={() => {
                    setRating((prev) =>
                      prev === 'thumbs-down' ? null : 'thumbs-down',
                    );
                  }}
                />
              </span>
            </span>
          </span>
        </AiModalHeader>
        <ModalBody className={styles.modalBody}>
          <div
            className={cx(zeckStyles.zeck, styles.sectionContent)}
            ref={scrollContainerRef}
            onScroll={linkTooltipBehavior.onScroll}
            {...linkTooltipBehavior.linkListeners}
          >
            <BlockLayout>
              {renderGroupListRenderables(
                content.map((block) => ({
                  data: { block },
                })),
                ({ data: { block }, i, spacing, groupedRenderableType }) => (
                  <BlockViewable
                    sectionId=""
                    key={block.type === 'image' ? block.guid : i}
                    className={cx(
                      groupedRenderableType === 'item'
                        ? getSpacing(spacing)
                        : '',
                      block.type === 'image' && styles.imageBlockOverride,
                    )}
                    scrollViewContainer={scrollContainerRef}
                    block={block}
                    linkables={linkables}
                    zeckPrevoteCapability={zeckPrevoteCapability}
                    zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
                  />
                ),
              )}
            </BlockLayout>
          </div>
        </ModalBody>
      </ModalPanel>

      <LinkTooltips
        linkTooltipState={linkTooltipBehavior.linkTooltipState}
        hideNotFoundTooltip={false}
      />

      <div className={styles.buttonMenu}>
        <Button
          onClick={() => {
            onInsertAbove(content);
            recordEvent('AI_INSERT');
          }}
          size="medium"
          color="transparent"
          className={styles.button}
        >
          <TextInsertIcon className={styles.buttonIcon} />
          Insert Above
        </Button>

        <Button
          onClick={() => {
            recordEvent('AI_REJECT');
            onClose();
          }}
          size="medium"
          color="transparent"
          className={styles.button}
        >
          <TrashIcon className={styles.buttonIcon} />
          Discard
        </Button>
      </div>
    </>
  );
};

export default AiContentModal;
