import React from 'react';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.js';
import HoverNextToPoint from '../../domHelpers/hoverNextTo/HoverNextToPoint.js';
import DetectsOutsideClick from '../../junkDrawer/DetectsOutsideClick.js';
import AddBlockMenu from './AddBlockMenu.js';

const AddingBlockMenu: React.FC<{
  blocksWithEl: ElementAndData<{ id: string }>[];
  targetBlockId: string;
  onCancel: () => void;
  addableBlocks: React.ReactNode;
}> = ({ blocksWithEl, targetBlockId, addableBlocks, onCancel }) => {
  useDocumentEventListener(
    'keydown',
    (e) => {
      if (['Shift', 'Meta', 'Alt', 'Control'].includes(e.key)) return;

      onCancel();
    },
    [onCancel],
  );

  return (
    <HoverNextToPoint
      viewportPolicy="none"
      containerStyles={{ zIndex: 'initial' }}
      // glob save us from z index
      // this is here to unset the default z index on hover next to (should probably be removed anyhow)
      // so that this menu stays within the stacking context of the scroll container (and doesn't go on
      // top of things it shouldn't)
      getPoint={(hoverEl) => {
        const blockEl = blocksWithEl
          .find(({ data }) => data.id === targetBlockId)
          ?.getEl();

        if (!blockEl) return null;

        const hoverRect = hoverEl.getBoundingClientRect();

        const rect = blockEl.getBoundingClientRect();
        const posBelowY = rect.y + rect.height;

        if (posBelowY + hoverRect.height > window.innerHeight) {
          return [rect.x, rect.y - hoverRect.height];
        }

        return [rect.x, posBelowY];
      }}
    >
      <DetectsOutsideClick<HTMLDivElement> onClick={onCancel}>
        {(ref) => <AddBlockMenu ref={ref}>{addableBlocks}</AddBlockMenu>}
      </DetectsOutsideClick>
    </HoverNextToPoint>
  );
};

export default AddingBlockMenu;
