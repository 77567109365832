import React, { ForwardedRef, RefObject, useEffect, useRef } from 'react';
import VoteView from '../voting/VoteView.tsx';
import ImageView from '../../../design-system/zeck/ImageView.tsx';
import FileWithDownload from '../FileWithDownload.tsx';
import Agenda from '../../../design-system/zeck/Agenda.tsx';
import Video from '../Video.tsx';
import Divider from '../Divider.tsx';
import TableView from '../../../design-system/zeck/TableView.tsx';
import { assertUnreachable, Block, TextBlock } from 'editor-content/Block.ts';
import mergeRefs from '../../../junkDrawer/mergeRefs.ts';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../voting/VoteCapability.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import CartaCapTableView from '../../../design-system/zeck/CartaCapTableView.tsx';
import BlockEditorAdapter from '../editor/BlockEditorAdapter.ts';
import { BulletedListItem } from '../../../design-system/zeck/BulletedList.tsx';
import { NumberedListItem } from '../../../design-system/zeck/NumberedList.tsx';
import Paragraph from '../../../design-system/zeck/Paragraph.tsx';
import Heading from '../../../design-system/zeck/Heading.tsx';
import Label from '../../../design-system/zeck/Label.tsx';
import { CommentsStateForSection } from './publish/commentsSidebar/useComments/useComments.ts';

export type BlockViewableProps = {
  sectionId: string;
  block: Block;
  key: React.Key;
  className?: string;
  commentsState?: CommentsStateForSection;
  scrollViewContainer: RefObject<HTMLElement>;
  linkables: Linkable[];
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  preview?: boolean;
};

const BlockViewable = React.forwardRef<HTMLElement, BlockViewableProps>(
  function PublishedBlockViewable(
    {
      sectionId,
      block,
      className,
      scrollViewContainer,
      linkables,
      zeckPrevoteCapability,
      zeckFinalizeVoteCapability,
      commentsState,
      preview,
    },
    forwardedRef,
  ) {
    switch (block.type) {
      case 'agenda': {
        return (
          <Agenda
            ref={mergeRefs([forwardedRef])}
            block={block}
            className={className}
            linkables={linkables}
          />
        );
      }
      case 'vote':
        return (
          <VoteView
            className={className}
            block={block}
            ref={mergeRefs([forwardedRef])}
            zeckPrevoteCapability={zeckPrevoteCapability}
            zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
          />
        );
      case 'bulleted-list-item':
        return (
          <BulletedListItemWithBlock
            className={className}
            block={block}
            indent={block.indent}
            linkables={linkables}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'numbered-list-item':
        return (
          <NumberedListItemWithBlock
            className={className}
            block={block}
            linkables={linkables}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'label':
        return (
          <LabelWithBlock
            className={className}
            block={block}
            linkables={linkables}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'paragraph':
        return (
          <ParagraphWithBlock
            className={className}
            block={block}
            linkables={linkables}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'image':
        return (
          <ImageView
            sectionId={sectionId}
            commentsState={commentsState}
            className={className}
            block={block}
            preview={preview}
            ref={mergeRefs([forwardedRef])}
            scrollContainerRef={scrollViewContainer}
          />
        );
      case 'file':
        return (
          <FileWithDownload
            className={className}
            block={block}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'heading':
        return (
          <HeadingWithBlock
            className={className}
            linkables={linkables}
            block={block}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'video':
        return <Video className={className} block={block} />;
      case 'divider':
        return <Divider className={className} block={block} />;
      case 'table':
        return <TableView className={className} block={block} />;
      case 'carta-cap-table':
        return <CartaCapTableView className={className} block={block} />;
    }

    assertUnreachable(block);
  },
);

export default BlockViewable;

const useRenderTextNodes = <R extends HTMLElement>(
  block: TextBlock,
  linkables: Linkable[],
) => {
  const ref = useRef<R>(null);
  useEffect(() => {
    const el = ref.current;
    if (el) {
      el.innerHTML = BlockEditorAdapter.toHTMLString(block.content, {
        linkables,
      });
    }
  }, [block, linkables]);

  return ref;
};

type WithBlockProps<B extends TextBlock> = {
  block: B;
  linkables: Linkable[];
};

const withBlock = <B extends TextBlock, P>(Wrapped: React.ComponentType<P>) =>
  React.forwardRef(
    (
      { block, linkables, ...otherProps }: P & WithBlockProps<B>,
      forwardedRef: ForwardedRef<HTMLElement>,
    ) => {
      const ref = useRenderTextNodes<HTMLElement>(block, linkables);

      return (
        <Wrapped
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({ ...otherProps } as any)}
          {...{
            ref: mergeRefs([ref, forwardedRef]),
          }}
          id={block.id}
        />
      );
    },
  );

const BulletedListItemWithBlock = withBlock(BulletedListItem);
const NumberedListItemWithBlock = withBlock(NumberedListItem);
const ParagraphWithBlock = withBlock(Paragraph);
const HeadingWithBlock = withBlock(Heading);
const LabelWithBlock = withBlock(Label);
