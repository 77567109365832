import cx from 'classnames';
import { ImageBlock } from 'editor-content/Block.ts';
import { CommentContent } from 'editor-content/CommentContent.js';
import { useEffect, useRef, useState } from 'react';
import CommentEditable from '../../../../../commentEditor/CommentEditable.js';
import CommentEditor from '../../../../../commentEditor/CommentEditor.js';
import Button from '../../../../../design-system/atoms/Button.js';
import InputCheckbox from '../../../../../design-system/atoms/InputCheckbox.js';
import OutlineButton from '../../../../../design-system/atoms/OutlineButton.js';
import useBreakpoints from '../../../../../services/useBreakpoints.ts';
import { AvailableTag } from '../../../../../types/AvailableTag.js';
import styles from './PublishedCommentForm.module.scss';
import PublishedCommentInputLayout from './PublishedCommentInputLayout.js';

/**
 * Comment, DirectMessage or object with content
 */
type CommentFormValue = {
  content: CommentContent;
  selection?: { block: ImageBlock };
  isDirectMessage: boolean;
};

type CommentFormProps = {
  availableTags: AvailableTag[];
  placeholder?: string;
  onCancel: () => void;
  onSubmit: (value: CommentFormValue) => Promise<void>;
  className?: string;
  testId: string;
  initialValue: CommentFormValue;
  canToggleDirectMessage: boolean;
  renderHelp?: (value: CommentFormValue) => React.ReactNode;
  selection?: { block: ImageBlock };
  mini?: boolean;
  submitText?: string;
};

function PublishedCommentForm({
  initialValue,
  availableTags,
  placeholder = '',
  onCancel,
  onSubmit,
  className,
  testId,
  canToggleDirectMessage,
  renderHelp,
  submitText = 'Submit',
  selection,
  mini,
}: CommentFormProps) {
  const [formState, setFormState] = useState<CommentFormValue>(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit({ ...formState, selection });
    setIsLoading(false);
  };

  const taggedUserIds = CommentEditor.getTaggedUserIds(formState.content);

  const { isMobile } = useBreakpoints();
  const canToggleDMs =
    canToggleDirectMessage &&
    (formState.isDirectMessage || taggedUserIds.length > 0);

  const isMobileForm = mini && isMobile;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobileForm) {
      if (ref.current) {
        const element = ref.current;
        const bounding = element.getBoundingClientRect();

        // Check if the element is within the viewport
        const isInViewport =
          bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight);

        if (!isInViewport) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }
    }
  }, [ref, isMobileForm, selection]);

  return (
    <div ref={ref}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
        className={cx(
          className,
          mini ? styles.comments__newComment_mini : styles.comments__newComment,
        )}
        data-testid={testId}
      >
        <PublishedCommentInputLayout
          mini={mini}
          footerSlot={
            canToggleDMs && (
              <div className={styles.commentForm__dm_info}>
                <InputCheckbox
                  backgroundColor="white"
                  label="Make a direct message visible only to people you ‘@’ tag."
                  value={formState.isDirectMessage}
                  onChange={(isDirectMessage) =>
                    setFormState((formState) => ({
                      ...formState,
                      isDirectMessage,
                    }))
                  }
                />
              </div>
            )
          }
        >
          <CommentEditable
            aria-label={`${testId}-input`}
            onPressEnter={handleSubmit}
            autofocus={!isMobileForm}
            scrollContainerRef={{ current: null }}
            availableTags={availableTags}
            tagTypeDisplayName="Viewers"
            placeholder={placeholder}
            commentContent={formState.content}
            onChange={(newContent) =>
              setFormState((formState) => ({
                ...formState,
                content: newContent,
              }))
            }
            className={styles.comments__comment__input}
          />
        </PublishedCommentInputLayout>

        {!mini && renderHelp && renderHelp(formState)}

        <div className={styles.comments__comment__newComment__buttons}>
          <OutlineButton
            size="medium"
            color="secondary"
            type="button"
            className={styles.comments__comment__cancel}
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
            data-testid={`cancel-${testId}`}
          >
            Cancel
          </OutlineButton>
          <Button
            data-testid={`submit-${testId}`}
            size={isMobile && mini ? 'small' : 'medium'}
            color="primary"
            type="submit"
            disabled={
              !CommentEditor.canSubmit({
                content: formState.content,
                selection: null,
              }) || isLoading
            }
          >
            {submitText}
          </Button>
        </div>
      </form>

      {mini && renderHelp && renderHelp(formState)}
    </div>
  );
}

export default PublishedCommentForm;
