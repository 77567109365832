import { ImageBlock } from 'editor-content/Block.ts';
import CommentVisibilityHelp from '../../pages/zeck/previewAndPublish/publish/commentsSidebar/CommentVisibilityHelp.tsx';
import PublishedCommentForm from '../../pages/zeck/previewAndPublish/publish/commentsSidebar/PublishedCommentForm.tsx';
import { CommentsStateForSection } from 'app/pages/zeck/previewAndPublish/publish/commentsSidebar/useComments/useComments.ts';
import useApi from '../../api/useApi.ts';
import { isNotPendingUser } from '../../types/User.ts';
import styles from './PublishedSelectionCommentForm.module.scss';
import DetectsOutsideClick from '../../junkDrawer/DetectsOutsideClick.tsx';
import UserInitials from '../atoms/UserInitials.tsx';
import initials from 'initials';

type Props = {
  sectionId: string;
  onClose: () => void;
  block: ImageBlock;
  commentsState?: CommentsStateForSection;
};

const PublishedSelectionCommentForm = ({
  onClose,
  commentsState,
  sectionId,
  block,
}: Props) => {
  const { createComment } = useApi();

  if (!commentsState) return null;

  const formClassName = styles.publishedSelectionCommentForm;

  const viewers = commentsState?.viewers?.filter(isNotPendingUser);
  const availableTags =
    viewers?.map((u) => ({
      userId: u.id,
      displayName: `${u.firstName} ${u.lastName}`,
    })) ?? [];

  return (
    <DetectsOutsideClick
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
      }}
    >
      {(ref) => (
        <div
          ref={ref}
          className={formClassName}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.publishedSelectionCommentForm__initials}>
            <UserInitials size="medium">
              {initials(commentsState.userName)}
            </UserInitials>
          </div>
          <div className={styles.publishedSelectionCommentForm__form}>
            <PublishedCommentForm
              testId="published-comment-image-form"
              placeholder="Add a comment or question..."
              renderHelp={({ isDirectMessage }) =>
                isDirectMessage ? null : (
                  <CommentVisibilityHelp
                    viewers={commentsState?.viewers ?? []}
                  />
                )
              }
              mini={true}
              availableTags={availableTags}
              onCancel={onClose}
              selection={{ block }}
              submitText="Comment"
              onSubmit={async function (comment) {
                commentsState?.openSectionComments();
                const newComment = await createComment(
                  sectionId,
                  comment.content,
                  comment.isDirectMessage,
                  comment.selection,
                );

                commentsState?.appendComment(newComment);

                onClose();
              }}
              initialValue={{
                content: [],
                isDirectMessage: false,
              }}
              canToggleDirectMessage={true}
            />
          </div>
        </div>
      )}
    </DetectsOutsideClick>
  );
};

export default PublishedSelectionCommentForm;
