import Point from '../../domHelpers/Point.js';
import Rect from '../../domHelpers/Rect.js';
import getDropIndex from './getDropIndex.js';
import getScrollNeeded from './getScrollNeeded.js';

export type BlockDragState =
  | { type: 'not-dragging' }
  | { type: 'dragging'; blockIndex: number; dropIndex: number | null };

type ScrollNeededSideEffect = { type: 'scroll-needed'; scrollAmount: number };
type DropBlockSideEffect = {
  type: 'drop-block';
  blockIndex: number;
  dropIndex: number;
};

export const dragStart = (
  initialState: BlockDragState,
  action: {
    blockIndex: number;
  },
): [newState: BlockDragState | null, sideEffect: null] => {
  switch (initialState.type) {
    case 'not-dragging': {
      return [
        {
          type: 'dragging',
          blockIndex: action.blockIndex,
          dropIndex: null,
        },
        null,
      ];
    }
    case 'dragging': {
      return [null, null];
    }
  }
};

export const mouseMove = (
  initialState: BlockDragState,
  action: {
    mousePos: Point;
    getBlockRects: (() => Rect | undefined)[];
    editorRect: Rect;
  },
): [
  newState: BlockDragState | null,
  sideEffect: ScrollNeededSideEffect | null,
] => {
  switch (initialState.type) {
    case 'not-dragging': {
      return [null, null];
    }
    case 'dragging': {
      const scrollAmountNeeded = getScrollNeeded(
        action.editorRect,
        action.mousePos,
      );

      const dropIndex = getDropIndex(
        action.getBlockRects,
        action.editorRect,
        action.mousePos,
      );

      return [
        {
          type: 'dragging',
          blockIndex: initialState.blockIndex,
          dropIndex,
        },
        scrollAmountNeeded === null
          ? null
          : {
              type: 'scroll-needed',
              scrollAmount: scrollAmountNeeded,
            },
      ];
    }
  }
};

export const dragEnd = (
  initialState: BlockDragState,
): [
  newState: BlockDragState | null,
  sideEffect: DropBlockSideEffect | null,
] => {
  switch (initialState.type) {
    case 'not-dragging': {
      return [null, null];
    }
    case 'dragging': {
      return [
        { type: 'not-dragging' },
        initialState.dropIndex === null
          ? null
          : {
              type: 'drop-block',
              blockIndex: initialState.blockIndex,
              dropIndex: initialState.dropIndex,
            },
      ];
    }
  }
};
