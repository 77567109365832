const editorKeyboardHandler =
  (editor: {
    onBackspace: () => boolean;
    onDelete: () => boolean;
    onEnter: () => void;
    onSelectUp: () => void;
    onSelectDown: () => void;
  }) =>
  (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Backspace':
        editor.onBackspace() && e.preventDefault();
        return;
      case 'Delete':
        editor.onDelete() && e.preventDefault();
        return;
      case 'Enter':
        if (e.getModifierState('Shift')) return;
        editor.onEnter();
        return;
      case 'ArrowUp':
        if (e.getModifierState('Shift')) editor.onSelectUp();
        return;
      case 'ArrowDown':
        if (e.getModifierState('Shift')) editor.onSelectDown();
        return;
    }
  };

export default editorKeyboardHandler;
