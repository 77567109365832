import { useRef, useState } from 'react';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.js';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import Point from '../../domHelpers/Point.js';
import useAnimationLoop from '../../services/useAnimationLoop.js';
import { getRectFromEl } from '../../domHelpers/Rect.js';
import isHTMLElement from '../../junkDrawer/isHTMLElement.js';
import logErrorOnce from '../../junkDrawer/debugHelpers/logErrorOnce.js';
import {
  BlockDragState,
  dragEnd,
  dragStart,
  mouseMove,
} from './BlockDragActions.js';

const AUTOSCROLL_SPEED_MAX = 50;
const MOUSEMOVE_EXEC_TIME_WARNING_THRESHOLD = 1000 / 60;

const useDragBlock = <BlockType extends { id: string; type: string }>(
  blocksWithEl: ElementAndData<BlockType>[],
  getScrollContainer: () => HTMLElement | null,
  onDrop: (startIndex: number, endIndex: number) => void,
  onDrag: () => void,
) => {
  const [dragState, setDragState] = useState<BlockDragState>({
    type: 'not-dragging',
  });

  const mousePosRef = useRef<Point>([-100, -100]);

  const onDragHandle = (blockIndex: number) => {
    onDrag();

    const [newState] = dragStart(dragState, {
      blockIndex,
    });

    newState && setDragState(newState);
  };

  const onMouseMove = (mousePos: [x: number, y: number]) => {
    mousePosRef.current = mousePos;
  };

  const onMouseUp = () => {
    const [newState, effect] = dragEnd(dragState);

    newState && setDragState(newState);

    effect &&
      effect.type === 'drop-block' &&
      onDrop(effect.blockIndex, effect.dropIndex);
  };

  // hook it up to the dom

  useDocumentEventListener(
    'mousemove',
    (e) => {
      const mousePos: Point = [e.clientX, e.clientY];
      onMouseMove(mousePos);
    },
    [dragState],
  );

  useDocumentEventListener(
    'keydown',
    (e) => {
      if (e.key === 'Escape') onMouseUp();
    },
    [dragState],
  );

  useDocumentEventListener(
    'mouseup',
    () => {
      onMouseUp();
    },
    [dragState],
  );

  // mouseMove is sampled by animation frame in order to not do expensive calculations more than needed
  useAnimationLoop(() => {
    const startTime = performance.now();

    const mousePos = mousePosRef.current;

    const scrollContainerEl = getScrollContainer();
    if (!scrollContainerEl) {
      logErrorOnce(
        'dnd-no-scroll-container',
        'Scroll container rect not present for editor drag and drop',
      );
      return;
    }

    const getBlockRects = blocksWithEl.map((blockWithEl) => () => {
      const blockEl = blockWithEl.getEl();
      if (!blockEl) {
        logErrorOnce(
          'dnd-no-block-rect',
          `Block rect not available during drag and drop for: ${blockWithEl.data.type} block with id ${blockWithEl.data.id}`,
        );
        return undefined;
      }

      return getRectFromEl(blockEl);
    });
    const editorRect = getRectFromEl(scrollContainerEl);

    const [newState, effect] = mouseMove(dragState, {
      editorRect: editorRect,
      getBlockRects,
      mousePos,
    });

    if (newState) {
      setDragState(newState);
    }

    if (effect) {
      if (effect.type === 'scroll-needed') {
        scrollContainerEl.scrollTop =
          scrollContainerEl.scrollTop +
          effect.scrollAmount * AUTOSCROLL_SPEED_MAX;
      }
    }

    const duration = performance.now() - startTime;
    if (duration >= MOUSEMOVE_EXEC_TIME_WARNING_THRESHOLD) {
      logErrorOnce(
        'dnd-duration',
        `Editor drag and drop took a long time, ${duration}ms`,
      );
    }

    return;
  }, [dragState]);

  const isDragging = dragState.type === 'dragging';

  return {
    onDragStart: onDragHandle,
    getDragOverlayPos: (): Point | null => mousePosRef.current,
    blockIndex: isDragging ? dragState.blockIndex : null,
    dropIndex: isDragging ? dragState.dropIndex : null,
    getDraggedEl: (): HTMLElement | null => {
      if (dragState.type !== 'dragging') {
        return null;
      }

      const blockEl = blocksWithEl[dragState.blockIndex]?.getEl();
      if (!blockEl) return null;

      const clonedNode = blockEl.cloneNode(true);

      if (!isHTMLElement(clonedNode)) return null;
      const { width } = blockEl.getBoundingClientRect();
      clonedNode.style.width = `${width}px`;
      clonedNode.style.setProperty('margin-top', '0px', 'important');

      return clonedNode;
    },
    isDragging,
  };
};
export type BlockDnd = ReturnType<typeof useDragBlock>;

export default useDragBlock;
