import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import AddingBlockMenu from './AddingBlockMenu.js';
import { compact } from 'lodash';
import AddBlockMenuSeparator from './AddBlockMenuSeparator.js';
import AddBlockMenuItem from './AddBlockMenuItem.js';
import { AddBlockItem } from './AddBlockConfiguration.js';

const AddingBlockMenuWithItems = <BlockType extends { id: string }>({
  targetBlockId,
  blocksWithEl,
  addBlockOptions,
  onPickBlockTypeSimple,
  onPickBlockTypeComplex,
  onCancel,
}: {
  targetBlockId: string;
  blocksWithEl: ElementAndData<BlockType>[];
  addBlockOptions: (AddBlockItem<BlockType> | false | null | undefined)[];
  onPickBlockTypeSimple: (newContent: BlockType[]) => void;
  onPickBlockTypeComplex: (blockType: string) => void;
  onCancel: () => void;
}) => {
  return (
    <AddingBlockMenu
      blocksWithEl={blocksWithEl}
      targetBlockId={targetBlockId}
      onCancel={onCancel}
      addableBlocks={compact(addBlockOptions).map((value) => {
        switch (value.type) {
          case 'separator':
            return (
              <AddBlockMenuSeparator key={value.label}>
                {value.label}
              </AddBlockMenuSeparator>
            );

          case 'block':
            return (
              <AddBlockMenuItem
                key={value.label}
                iconName={value.iconName}
                onClick={() => {
                  onPickBlockTypeSimple(value.createNewBlock());
                }}
                label={value.label}
                description={value.description}
              />
            );
          case 'complex-block':
            return (
              <AddBlockMenuItem
                key={value.label}
                iconName={value.iconName}
                onClick={() => {
                  onPickBlockTypeComplex(value.blockType);
                }}
                label={value.label}
                description={value.description}
              />
            );
        }
      })}
    />
  );
};

export default AddingBlockMenuWithItems;
