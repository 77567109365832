import ZeckEditorSelection, {
  getBodySelection,
} from './ZeckEditorSelection.js';
import { EditorContent } from '../edit/useEditorState.js';
import ZeckEditor, { ZeckEditorState } from './ZeckEditor/ZeckEditor.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import EditorData from './EditorData.js';
import { useCallback } from 'react';

type ZeckEditorActionVoid<T extends unknown[]> = (
  content: EditorContent,
  selection: ZeckEditorSelection,
  ...args: T
) => ZeckEditorState | void;

type ZeckEditorAction<T extends unknown[], ReturnType> = (
  content: EditorContent,
  selection: ZeckEditorSelection,
  ...args: T
) => [ZeckEditorState, ReturnType] | void;

type ZeckEditorActionAlwaysHandled<T extends unknown[], ReturnType> = (
  content: EditorContent,
  selection: ZeckEditorSelection,
  ...args: T
) => [ZeckEditorState, ReturnType];

const useZeckEditor = (
  state: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  },
  onChange: (state: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  }) => void,
) => {
  const { content, selection } = state;

  const bodyContent = content.body;

  const adaptZeckEditorActionVoid = <T extends unknown[]>(
    action: ZeckEditorActionVoid<T>,
  ) =>
    // Silence error about name of this function
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCallback(
      (...args: T): boolean => {
        const result = action(content, selection, ...args);
        if (!result) return false;

        onChange(result);
        return true;
      },

      // Linter fails to detect that these are needed
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [action, content, selection, onChange],
    );

  const adaptZeckEditorAction = <T extends unknown[], ReturnType>(
    action: ZeckEditorAction<T, ReturnType>,
  ) =>
    // Silence error about name of this function
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCallback(
      (...args: T): ReturnType | void => {
        const result = action(content, selection, ...args);
        if (!result) return;
        const [newState, returnValue] = result;

        onChange(newState);
        return returnValue;
      },

      // Linter fails to detect that these are needed
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [action, content, selection, onChange],
    );

  const adaptZeckEditorActionAlwaysHandled = <T extends unknown[], ReturnType>(
    action: ZeckEditorActionAlwaysHandled<T, ReturnType>,
  ) =>
    // Silence error about name of this function
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCallback(
      (...args: T): ReturnType => {
        const result = action(content, selection, ...args);
        const [newState, returnValue] = result;

        onChange(newState);
        return returnValue;
      },

      // Linter fails to detect that these are needed
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [action, content, selection, onChange],
    );

  return {
    bodySelection: getBodySelection(selection),
    bodyContent,
    onSelectTitle: adaptZeckEditorActionVoid(ZeckEditor.selectTitle),
    onSelectHeadline: adaptZeckEditorActionVoid(ZeckEditor.selectHeadline),
    onSelectBody: adaptZeckEditorActionVoid(ZeckEditor.selectBody),
    onChangeTitle: adaptZeckEditorActionVoid(ZeckEditor.editTitle),
    onChangeHeadline: adaptZeckEditorActionVoid(ZeckEditor.editHeadline),
    onAddBlockAtEnd: adaptZeckEditorAction(ZeckEditor.addBlockAtEnd),

    onNavLeft: adaptZeckEditorActionVoid(ZeckEditor.navLeft),
    onNavRight: adaptZeckEditorActionVoid(ZeckEditor.navRight),
    onSelectUp: adaptZeckEditorActionVoid(ZeckEditor.selectUp),
    onSelectDown: adaptZeckEditorActionVoid(ZeckEditor.selectDown),
    onBackspace: adaptZeckEditorActionVoid(ZeckEditor.pressBackspace),
    onEnter: adaptZeckEditorActionVoid(ZeckEditor.pressEnter),
    onDelete: adaptZeckEditorActionVoid(ZeckEditor.pressDelete),
    onPastePlaintext: adaptZeckEditorActionVoid(ZeckEditor.pastePlaintext),
    onPasteBlocks: adaptZeckEditorActionVoid(ZeckEditor.pasteBlocks),
    onPasteText: adaptZeckEditorActionVoid(ZeckEditor.pasteText),
    onPasteImage: adaptZeckEditorActionVoid(ZeckEditor.pasteImage),
    // onAddHighlight: adaptZeckEditorAction(ZeckEditor.addHighlight),
    onToggleFormat: adaptZeckEditorActionVoid(ZeckEditor.toggleFormat),
    onAddLink: adaptZeckEditorActionVoid(ZeckEditor.addLink),
    onSetImageWidth: adaptZeckEditorActionVoid(ZeckEditor.setImageWidth),
    onSetImageAlign: adaptZeckEditorActionVoid(ZeckEditor.setImageAlign),
    onReplaceImage: adaptZeckEditorActionVoid(ZeckEditor.replaceImage),
    onReplaceFile: adaptZeckEditorActionVoid(ZeckEditor.replaceFile),
    onReplaceTable: adaptZeckEditorActionVoid(ZeckEditor.replaceTable),
    onTurnInto: adaptZeckEditorActionVoid(ZeckEditor.turnInto),
    onIndent: adaptZeckEditorActionVoid(ZeckEditor.indent),
    onEditBlock: adaptZeckEditorActionVoid(ZeckEditor.editBlock),
    onInsertAiContentAbove: adaptZeckEditorAction(
      ZeckEditor.insertAiContentAbove,
    ),

    onCut: adaptZeckEditorAction(ZeckEditor.cut),

    onCopy: (): EditorData<HydratedBlock> | void =>
      ZeckEditor.copy(content, selection),

    AddBlock: {
      addNewBlock: adaptZeckEditorActionAlwaysHandled(
        ZeckEditor.AddBlock.addNewBlock,
      ),
      replaceNewBlock: adaptZeckEditorAction(
        ZeckEditor.AddBlock.replaceNewBlock,
      ),
    },

    loseFocus: adaptZeckEditorActionVoid(ZeckEditor.loseFocus),

    dropDraggedBlock: adaptZeckEditorAction(ZeckEditor.dropDraggedBlock),
  };
};

// HEY YOU
// don't put logic in here, this layer exists to adapt functional zeck editor actions
// to a stateful react world

export default useZeckEditor;
