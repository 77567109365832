import { useRef, useState } from 'react';
import styles from './ViewTopBar.module.scss';
import IconButton from '../../../design-system/atoms/IconButton.tsx';
import TopBarLayout from '../../../design-system/layouts/TopBarLayout.tsx';
import ZeckEditMenu, {
  ZeckEditMenuLinkItem,
} from '../sharedTopBar/ZeckEditMenu.tsx';
import { PublishedZeck, Zeck } from '../../../types.ts';
import useApi from '../../../api/useApi.ts';
import { getSystemTimezone } from '../../../dateTime/timestamp.ts';
import { useSignal } from '../../../signals/useSignal.ts';
import ExportPdfModal, { Pdf } from './ExportPdfModal.tsx';

type ViewTopBarProps = {
  onClickOpenSidebar(e: React.MouseEvent<HTMLButtonElement>): void;
  zeck: Zeck | PublishedZeck;
  children: React.ReactNode;
  showKebab: boolean;
};

const ViewTopBar = ({
  children,
  onClickOpenSidebar,
  zeck,
  showKebab,
}: ViewTopBarProps) => {
  const { getPublishedZeckPdf } = useApi();
  const pdf$ = useSignal<null | Pdf>(null);

  const kebabButtonRef = useRef<HTMLButtonElement>(null);
  const [showManagementMenu, setShowManagementMenu] = useState(false);
  return (
    <>
      <TopBarLayout
        className={styles.sectionPreviewTopBar}
        {...{
          leftSlot: (
            <IconButton
              name="hamburger"
              aria-label="open sidebar"
              onClick={onClickOpenSidebar}
            />
          ),
          rightSlot: showKebab ? (
            <IconButton
              name="kebab"
              ref={kebabButtonRef}
              aria-label="open section management menu"
              onClick={() => {
                setShowManagementMenu(!showManagementMenu);
              }}
            />
          ) : undefined,
        }}
      >
        <div className={styles.sectionPreviewTopBar__title}>{children}</div>
      </TopBarLayout>
      {showKebab && (
        <ZeckEditMenu
          element={kebabButtonRef}
          positionStrategy={(el, childElement) => {
            const childElementWidth =
              childElement.getBoundingClientRect().width;
            const rect = el.getBoundingClientRect();
            return [
              rect.x + rect.width - childElementWidth,
              rect.y + rect.height + 8,
            ];
          }}
          isOpen={showManagementMenu}
          onClose={() => {
            setShowManagementMenu(false);
          }}
        >
          <ZeckEditMenuLinkItem
            {...{
              iconName: 'export',
              label: 'Export to PDF',
              sublabel: 'Save to PDF or Print',
              href: '#',
            }}
            onClick={(event) => {
              if (!('zeckId' in zeck)) {
                return;
              }

              event.preventDefault();
              event.stopPropagation();

              pdf$.set({
                zeck,
                url: getPublishedZeckPdf({
                  zeckId: zeck.zeckId,
                  timeZone: getSystemTimezone(),
                }),
              });
            }}
          />
        </ZeckEditMenu>
      )}
      <ExportPdfModal pdf$={pdf$} />
    </>
  );
};
export default ViewTopBar;
