import {
  createBulletedListItemBlock,
  createParagraphBlock,
} from 'editor-content/Block.js';

import React, { useCallback } from 'react';
import { CoordinatorEvent, PublicEditorEvent } from '../../EditorEvents.ts';
import ResolutionTemplateModal from '../../../pages/meetingMinutes/edit/components/ResolutionTemplateModal.tsx';
import { VoteType } from 'editor-content/VoteBlock.js';
import { generateEmptyVoteContent } from 'editor-content/lib/generateVoteContent.js';
import { MeetingMinutesBlock } from 'editor-content/MeetingMinutes/MeetingMinutesBlock.ts';
import { useAddBlock } from '../AddBlockExperience.js';
import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.js';
import { AddBlockItem } from '../AddBlockConfiguration.js';

export const useAddBlockMeetingMinutes = ({
  dispatch,
  blocksWithEl,
  leftGutterRef,
}: {
  dispatch: (
    event:
      | PublicEditorEvent<MeetingMinutesBlock>
      | CoordinatorEvent<MeetingMinutesBlock>,
  ) => void;
  blocksWithEl: ElementAndData<MeetingMinutesBlock>[];
  leftGutterRef: React.RefObject<HTMLElement>;
}) => {
  const onAddNewBlock = (targetIndex: number) => {
    const newBlock = createParagraphBlock([]);
    dispatch({
      type: 'addNewBlock',
      data: {
        targetIndex,
        newBlock,
      },
    });
    return newBlock.id;
  };
  const onReplaceNewBlock = (
    newContent: MeetingMinutesBlock[],
    targetBlockId: string,
  ) => {
    dispatch({
      type: 'replaceNewBlock',
      data: {
        targetBlockId,
        newContent,
      },
    });
  };
  const onConfigureComplexBlock = useCallback(() => {
    dispatch({
      type: 'selection',
      data: null,
    });
  }, [dispatch]);

  const addBlockOptions: AddBlockItem<MeetingMinutesBlock>[] = [
    {
      type: 'block',
      label: 'Bulleted List',
      blockType: 'bulleted-list-item',
      description: 'A simple list of items',
      iconName: 'bulletedList',
      createNewBlock: () => [createBulletedListItemBlock([], 0)],
    },
    {
      type: 'complex-block',
      label: 'Resolution Template',
      blockType: 'paragraph',
      description: 'Insert template language for votes',
      iconName: 'vote',
      renderAdding: ({ onAdd, onCancel }) => (
        <ResolutionTemplateModal
          isOpen
          onClose={onCancel}
          onInsert={function (voteType: VoteType): void {
            onAdd(generateEmptyVoteContent(voteType));
          }}
        />
      ),
    },
  ];

  return useAddBlock({
    blocksWithEl,
    leftGutterRef,
    onAddNewBlock,
    onReplaceNewBlock,
    onConfigureComplexBlock,
    addBlockOptions,
  });
};
