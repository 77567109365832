import React, { useState } from 'react';
import styles from './CommentThread.module.scss';
import { CommentOrReply } from './commentOrReply/CommentOrReply.js';
import PublishedCommentForm from './PublishedCommentForm.js';
import { AvailableTag } from '../../../../../types/AvailableTag.js';
import {
  CommentReplyWithActions,
  CommentWithActions,
} from './useComments/useComments.js';
import cx from 'classnames';

export const CommentThreadPanel = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'> & {
    isSelected?: boolean;
    isResolved?: boolean;
    isDm?: boolean;
  }
>(function CommentThreadPanel({ isSelected, isResolved, isDm, ...props }, ref) {
  return (
    <div
      {...props}
      ref={ref}
      className={cx(
        styles.commentThread,
        isSelected && styles.commentThread_isSelected,
        isResolved && styles.commentThread_resolved,
        isDm && styles.commentThread_directMessage,
      )}
    />
  );
});

type CommentThreadProps = {
  comment: CommentWithActions;
  commentReplies: CommentReplyWithActions[];
  commentViewers: AvailableTag[];
  resolved: boolean;
  isDM: boolean;
};

const CommentThread: React.FC<CommentThreadProps> = ({
  comment,
  commentReplies,
  commentViewers,
  resolved,
  isDM,
}) => {
  const [isReplying, setIsReplying] = useState(false);

  return (
    <CommentThreadPanel
      data-testid="comment-thread"
      isResolved={resolved}
      isDm={isDM}
    >
      {isDM && (
        <div className={styles.commentThread__header}>
          <span className={styles.commentThread__directMessageLabel}>
            Direct Message
          </span>
          {commentViewers.map((viewer) => (
            <span
              key={viewer.userId}
              className={styles.commentThread__directMessageViewerPill}
            >
              {viewer.displayName}
            </span>
          ))}
        </div>
      )}
      <CommentOrReply
        isReply={false}
        data-testid="comment"
        commentTypeLabel={isDM ? 'Direct Message' : 'Comment'}
        userName={comment.userName}
        content={comment.content}
        starred={comment.starred}
        selection={comment.selection}
        createdAt={comment.createdAt}
        updatedAt={comment.updatedAt}
        onEdit={comment.actions.edit}
        onDelete={comment.actions.delete}
        onResolve={comment.actions.resolve}
        onUnresolve={comment.actions.unresolve}
        onToggleStar={comment.actions.toggleStar}
      />
      {commentReplies.map((commentReply) => (
        <CommentOrReply
          isReply={true}
          data-testid="reply"
          className={styles.commentThread__commentReply}
          key={commentReply.id}
          commentTypeLabel={isDM ? 'Direct Message' : 'Comment'}
          userName={commentReply.userName}
          content={commentReply.content}
          createdAt={commentReply.createdAt}
          updatedAt={commentReply.updatedAt}
          onEdit={commentReply.actions.edit}
          onDelete={commentReply.actions.delete}
        />
      ))}

      {resolved ? (
        <div className={styles.commentThread__resolvedLabel}>
          Marked as Resolved
        </div>
      ) : isReplying ? (
        <PublishedCommentForm
          className={styles.commentThread__form}
          placeholder="Add a reply..."
          availableTags={commentViewers}
          initialValue={{
            content: [],
            isDirectMessage: isDM,
          }}
          canToggleDirectMessage={false}
          onCancel={() => setIsReplying(false)}
          onSubmit={async (value) => {
            await comment.actions.reply(value.content);
            setIsReplying(false);
          }}
          testId="new-reply"
          submitText="Reply"
        />
      ) : (
        <button
          type="button"
          onClick={() => {
            setIsReplying(true);
          }}
          className={styles.commentThread__replyButton}
        >
          Reply
        </button>
      )}
    </CommentThreadPanel>
  );
};

export default CommentThread;
