import React, { useState } from 'react';
import {
  CommentContent,
  CommentContentNode,
} from 'editor-content/CommentContent.js';
import styles from './CommentOrReply.module.scss';
import CommentEditorAdapter from '../../../../../../commentEditor/CommentEditorAdapter.js';
import CommentKebab from './../CommentKebab.js';
import PublishedCommentForm from './../PublishedCommentForm.js';
import CommentNameplate from './../CommentNameplate.js';
import PublishedCommentLayout from './../PublishedCommentLayout.tsx';
import IconButton from '../../../../../../design-system/atoms/IconButton.tsx';
import Image from '../../../../../../design-system/atoms/Image.tsx';
import WithTooltip from '../../../../../../design-system/organisms/WithTooltip.tsx';
import useFetchImage from '../../../../../../services/useFetchImage.tsx';
import { Comment } from 'api-client/types.js';
import { ImageBlock } from 'editor-content/Block.ts';
import useBreakpoints from '../../../../../../services/useBreakpoints.ts';

const QuotedImageBlock = ({ block }: { block: ImageBlock }) => {
  const { guid, dimensions } = block;
  const src = useFetchImage(guid);

  const { isMobile } = useBreakpoints();
  const imageWidth = isMobile ? 200 : 160;
  const imageHeight = isMobile ? 160 : 112;
  const originalHeight = dimensions?.height ?? imageWidth;
  const originalWidth = dimensions?.width ?? imageWidth;

  const calculatedHeight =
    (dimensions?.width ?? 0) > (dimensions?.height ?? 0)
      ? (originalHeight / originalWidth) * imageWidth
      : imageHeight;

  const calculatedWidth =
    (dimensions?.width ?? 0) > (dimensions?.height ?? 0)
      ? imageWidth
      : (originalWidth / originalHeight) * imageHeight;

  return (
    <div
      style={{ width: calculatedWidth }}
      className={styles.commentOrReply__imageBlock}
    >
      <Image
        src={src}
        dimensions={{ width: calculatedWidth, height: calculatedHeight }}
      />
    </div>
  );
};

export const CommentOrReply: React.FC<{
  className?: string;
  userName: string;
  content: CommentContentNode[];
  commentTypeLabel: string;
  selection?: Comment['selection'];

  isReply: boolean;
  createdAt: number;
  updatedAt: number;
  starred?: boolean;
  onEdit?: (content: CommentContent) => Promise<void>;
  onDelete?: () => void;
  onResolve?: () => void;
  onUnresolve?: () => void;
  onToggleStar?: () => void;
}> = ({
  className,
  userName,
  content,
  commentTypeLabel,
  isReply,
  createdAt,
  updatedAt,
  selection,
  starred,
  onEdit,
  onDelete,
  onResolve,
  onUnresolve,
  onToggleStar,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const showStar = !onUnresolve && !isReply;

  const starredTooltip = starred ? 'Starred' : 'Not Starred';

  const onResolveClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onResolve) {
      return onResolve();
    }
  };

  const onStarClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onToggleStar) {
      return onToggleStar();
    }
  };

  return (
    <PublishedCommentLayout className={className} {...props}>
      <CommentNameplate
        userName={userName}
        timestamp={createdAt}
        actionsSlot={
          <>
            {!isReply && onResolve && (
              <WithTooltip<HTMLButtonElement> text="Mark as resolved and hide">
                {(ref, listeners) => (
                  <IconButton
                    name="resolve"
                    aria-label="resolve"
                    className={`${styles.commentOrReply__resolveButton} exposeOnAncestorHover`}
                    onClick={onResolveClick}
                    ref={ref}
                    {...listeners}
                  />
                )}
              </WithTooltip>
            )}
            {showStar && (
              <WithTooltip<HTMLButtonElement> text={starredTooltip}>
                {(ref, listeners) => (
                  <IconButton
                    name={starred ? 'yellowStar' : 'star'}
                    aria-label="star"
                    className={`${starred ? styles.commentOrReply__starButton__starred : styles.commentOrReply__starButton__unstarred} exposeOnAncestorHover`}
                    onClick={onStarClick}
                    ref={ref}
                    {...listeners}
                  />
                )}
              </WithTooltip>
            )}

            <CommentKebab
              commentTypeLabel={commentTypeLabel}
              onUnresolve={onUnresolve}
              onClickEdit={
                onEdit &&
                (() => {
                  setIsEditing(true);
                })
              }
              onDelete={onDelete}
            />
          </>
        }
      />
      {isEditing ? (
        <PublishedCommentForm
          className={styles.commentOrReply__editForm}
          availableTags={[]}
          onCancel={() => setIsEditing(false)}
          onSubmit={async (value) => {
            if (!onEdit) return;
            await onEdit(value.content);
            setIsEditing(false);
          }}
          testId="edit-comment"
          initialValue={{
            isDirectMessage: false,
            content,
          }}
          canToggleDirectMessage={false}
          submitText="Save"
        />
      ) : (
        <p className={styles.commentOrReply__content}>
          {selection?.block && <QuotedImageBlock block={selection.block} />}

          <span
            dangerouslySetInnerHTML={{
              __html: CommentEditorAdapter.toHTMLString(content),
            }}
          />
          {updatedAt > createdAt && (
            <span className={styles.commentOrReply__edited}> (edited)</span>
          )}
        </p>
      )}
    </PublishedCommentLayout>
  );
};
