import { EditorContent } from '../../edit/useEditorState.js';
import ZeckEditorSelection, {
  getBodySelection,
  getHeadlineSelection,
  getTitleSelection,
  isBodySelection,
  isHeadlineSelection,
  isTitleSelection,
  ZeckBodySelection,
} from '../ZeckEditorSelection.js';
import BodyEditor, {
  BodySelection as BodySelectionBase,
  BodyState,
  BodyStateSelected,
} from '../BodyEditor/BodyEditor.js';
import {
  contentSelection,
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import {
  isTextSelection,
  textSelection,
} from '../../../../editor/selection/TextSelection.js';
import { allTextNodes } from 'editor-content/TextNode.js';
import { getStartOfBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import { isCompletedVoteBlock } from '../../../../VoteBlockHydrated.js';
import EditorData from '../EditorData.js';
import AddBlockEditorActions from '../../../../editor/addBlock/AddBlockEditorActions.js';
import HeadlineEditor, {
  HeadlineSelection,
  HeadlineState,
  HeadlineStateSelected,
} from '../HeadlineEditor/HeadlineEditor.js';
import TitleEditor, {
  TitleSelection,
  TitleState,
  TitleStateSelected,
} from '../TitleEditor/TitleEditor.js';
import pressEnterZeck from '../pressEnter/pressEnterZeck.js';
import {
  applyBodyContent,
  applyBodySelection,
  applyBodyState as applyBodyStateBase,
  applyHeadlineSelection,
  applyHeadlineState,
  applyTitleSelection,
  applyTitleState,
  deselect,
} from './ZeckEditorState.js';
import { pipe } from '../../../../result/Result.js';
import generateZeckBlockEditorAddBlock from '../../../../editor/addBlock/zeck/generateZeckBlockEditorAddBlock.js';

export type ZeckEditorState = {
  content: EditorContent;
  selection: ZeckEditorSelection;
};

// This is normally a pass-thru, but will return modified content and selection
// when a new bodyState attempts to remove undeletable blocks
const getPreservedBodyState = (
  currentState: { content: EditorContent; selection: ZeckBodySelection },
  bodyState: BodyState,
): BodyState => {
  const undeletableBlocks = currentState.content.body
    .filter(isCompletedVoteBlock)
    .filter((block) => !bodyState.content.map((b) => b.id).includes(block.id));

  if (!undeletableBlocks.length) {
    return { content: bodyState.content, selection: bodyState.selection };
  }

  const selectionStart = isTextSelection(currentState.selection)
    ? currentState.selection.index
    : getStartOfBlockSelection(currentState.selection);

  const maybeSelectionIndex = isTextSelection(bodyState.selection)
    ? { index: bodyState.selection.index + +undeletableBlocks.length }
    : {};

  return {
    content: [
      ...bodyState.content.slice(0, selectionStart),
      ...undeletableBlocks,
      ...bodyState.content.slice(selectionStart),
    ],
    selection: bodyState.selection && {
      ...bodyState.selection,
      ...maybeSelectionIndex,
    },
  };
};

const applyBodyState = (
  currentState: { content: EditorContent; selection: ZeckBodySelection },
  bodyState: BodyState | void,
): ZeckEditorState => {
  if (!bodyState) return currentState;

  const { content, selection } = getPreservedBodyState(currentState, bodyState);

  return {
    content: {
      ...currentState.content,
      body: content,
    },
    selection: selection && {
      target: 'body',
      ...selection,
    },
  };
};

const bodyAction =
  <ArgsType extends unknown[], ReturnType>(
    action: (
      state: BodyState,
      ...args: ArgsType
    ) => [newState: BodyState, returnValue: ReturnType],
  ) =>
  (
    content: EditorContent,
    selection: ZeckEditorSelection,
    ...args: ArgsType
  ): [newState: ZeckEditorState, returnValue: ReturnType] => {
    const [state, result] = action(
      {
        content: content.body,
        selection: getBodySelection(selection),
      },
      ...args,
    );

    return [pipe({ content, selection }, applyBodyStateBase(state)), result];
  };

const convertToStateVoid =
  <ArgsType extends unknown[], ReturnType>(
    action: (...args: ArgsType) => ReturnType,
  ) =>
  (...args: ArgsType): [ReturnType, void] => {
    return [action(...args), undefined];
  };

const selectionBasedAction =
  <ArgsType extends unknown[]>(handlers: {
    title?: (state: TitleStateSelected, ...args: ArgsType) => TitleState | void;
    headline?: (
      state: HeadlineStateSelected,
      ...args: ArgsType
    ) => HeadlineState | void;
    body?: (state: BodyStateSelected, ...args: ArgsType) => BodyState | void;
  }) =>
  (
    content: EditorContent,
    selection: ZeckEditorSelection,
    ...args: ArgsType
  ) => {
    if (!selection) return;

    switch (selection.target) {
      case 'title': {
        if (!handlers.title) return;

        const result = handlers.title(
          { content: content.title, selection },
          ...args,
        );

        if (!result) return;

        return pipe({ content, selection }, applyTitleState(result));
      }
      case 'headline': {
        if (!handlers.headline) return;

        const result = handlers.headline(
          {
            content: content.headline,
            selection,
          },
          ...args,
        );

        if (!result) return;

        return pipe({ content, selection }, applyHeadlineState(result));
      }
      case 'body': {
        if (!handlers.body) return;

        const result = handlers.body(
          {
            content: content.body,
            selection,
          },
          ...args,
        );

        if (!result) return;

        return applyBodyState({ content, selection }, result);
      }
    }
  };

const ZeckEditor = {
  editTitle: selectionBasedAction({ title: TitleEditor.edit }),
  editHeadline: selectionBasedAction({ headline: HeadlineEditor.edit }),
  editBlock: selectionBasedAction({ body: BodyEditor.editBlock }),

  // always action
  selectTitle: (
    content: EditorContent,
    selection: ZeckEditorSelection,
    newTitleSelection: TitleSelection,
  ): ZeckEditorState =>
    pipe(
      { content, selection },
      applyTitleState(
        TitleEditor.select(
          {
            content: content.title,
            selection: getTitleSelection(selection),
          },
          newTitleSelection,
        ),
      ),
    ),
  selectHeadline: (
    content: EditorContent,
    selection: ZeckEditorSelection,
    newHeadlineSelection: HeadlineSelection,
  ): ZeckEditorState =>
    pipe(
      { content, selection },
      applyHeadlineState(
        HeadlineEditor.select(
          {
            content: content.headline,
            selection: getHeadlineSelection(selection),
          },
          newHeadlineSelection,
        ),
      ),
    ),
  selectBody: (
    content: EditorContent,
    selection: ZeckEditorSelection,
    newBodySelection: BodySelectionBase | null,
  ): ZeckEditorState => {
    const result = BodyEditor.select(
      {
        content: content.body,
        selection: getBodySelection(selection),
      },
      newBodySelection,
    );

    return pipe(
      { content, selection },
      result.selection ? applyBodySelection(result.selection) : deselect,
      applyBodyContent(result.content),
    );
  },

  navLeft(
    content: EditorContent,
    selection: ZeckEditorSelection,
  ): ZeckEditorState {
    if (isHeadlineSelection(selection)) {
      return pipe(
        { content, selection },
        applyTitleSelection(contentSelection(content.title.length)),
      );
    }
    if (isBodySelection(selection)) {
      const actionResult = BodyEditor.navLeft({
        content: content.body,
        selection,
      });
      if (actionResult) {
        return applyBodyState({ content, selection }, actionResult);
      } else {
        const offset = Array.from(allTextNodes(content.headline)).reduce(
          (acc, { text }) => acc + text.length,
          0,
        );

        return pipe(
          { content, selection },
          applyHeadlineSelection(contentSelection(offset)),
        );
      }
    }

    return { content, selection };
  },
  navRight(
    content: EditorContent,
    selection: ZeckEditorSelection,
  ): ZeckEditorState {
    if (isTitleSelection(selection)) {
      return pipe(
        { content, selection },
        applyHeadlineSelection(contentSelection(0)),
      );
    }
    if (isHeadlineSelection(selection)) {
      return pipe(
        { content, selection },
        applyBodySelection(textSelection(0, contentSelection(0))),
      );
    }
    if (isBodySelection(selection)) {
      const actionResult = BodyEditor.navRight({
        content: content.body,
        selection,
      });
      if (actionResult) {
        return applyBodyState({ content, selection }, actionResult);
      }
      return { content, selection };
    }

    return { content, selection };
  },

  selectUp: selectionBasedAction({
    body: BodyEditor.selectUp,
  }),
  selectDown: selectionBasedAction({
    body: BodyEditor.selectDown,
  }),

  pressBackspace(
    content: EditorContent,
    selection: ZeckEditorSelection,
  ): ZeckEditorState | void {
    if (isBodySelection(selection)) {
      const bodyResult = BodyEditor.pressBackspace({
        content: content.body,
        selection: selection,
      });

      if (!bodyResult) return;

      switch (bodyResult.type) {
        case 'change':
          return applyBodyState({ content, selection }, bodyResult);
        case 'merge':
          return pipe(
            { content, selection },
            applyBodyContent(bodyResult.content),
            applyHeadlineState(
              HeadlineEditor.receiveBackspace(
                {
                  content: content.headline,
                  selection: getHeadlineSelection(selection),
                },
                bodyResult.blockContent,
              ),
            ),
          );
      }
    } else if (isHeadlineSelection(selection)) {
      if (isCollapsed(selection) && selection.anchorOffset === 0) {
        return pipe(
          { content, selection },
          applyTitleSelection(contentSelection(content.title.length)),
        );
      }
    }
    return;
  },

  pressDelete(
    content: EditorContent,
    selection: ZeckEditorSelection,
  ): ZeckEditorState | void {
    if (isBodySelection(selection)) {
      const bodyResult = BodyEditor.pressDelete({
        content: content.body,
        selection,
      });

      if (!bodyResult) return;
      return applyBodyState({ content, selection }, bodyResult);
    }

    return;
  },

  pressEnter: pressEnterZeck,

  pastePlaintext: selectionBasedAction({
    body: BodyEditor.pastePlaintext,
  }),

  pasteBlocks: selectionBasedAction({
    body: BodyEditor.pasteBlocks,
  }),

  pasteText: selectionBasedAction({
    body: BodyEditor.pasteText,
  }),

  pasteImage: selectionBasedAction({
    body: BodyEditor.pasteImage,
  }),

  cut(
    content: EditorContent,
    selection: ZeckEditorSelection,
  ): [ZeckEditorState, EditorData<HydratedBlock>] | void {
    if (isBodySelection(selection)) {
      const result = BodyEditor.cut({
        content: content.body,
        selection,
      });

      if (!result) return;

      return [applyBodyState({ content, selection }, result[0]), result[1]];
    }
  },

  copy(
    content: EditorContent,
    selection: ZeckEditorSelection,
  ): EditorData<HydratedBlock> | void {
    if (isBodySelection(selection)) {
      return BodyEditor.copy({
        content: content.body,
        selection,
      });
    }
  },

  loseFocus(content: EditorContent) {
    return {
      content,
      selection: null,
    };
  },

  addHighlight: selectionBasedAction({
    headline: HeadlineEditor.addHighlight,
    body: BodyEditor.addHighlight,
  }),
  toggleFormat: selectionBasedAction({
    headline: HeadlineEditor.toggleFormat,
    body: BodyEditor.toggleFormat,
  }),
  addLink: selectionBasedAction({
    headline: HeadlineEditor.addLink,
    body: BodyEditor.addLink,
  }),

  setImageWidth: selectionBasedAction({ body: BodyEditor.setImageWidth }),
  setImageAlign: selectionBasedAction({ body: BodyEditor.setImageAlign }),
  replaceImage: selectionBasedAction({ body: BodyEditor.replaceImage }),
  replaceFile: selectionBasedAction({ body: BodyEditor.replaceFile }),
  replaceTable: selectionBasedAction({ body: BodyEditor.replaceTable }),
  turnInto: selectionBasedAction({ body: BodyEditor.turnInto }),
  indent: selectionBasedAction({ body: BodyEditor.indent }),

  addBlockAtEnd: bodyAction(convertToStateVoid(BodyEditor.addBlockAtEnd)),
  insertAiContentAbove: bodyAction(
    convertToStateVoid(BodyEditor.insertAiContentAbove),
  ),

  AddBlock: {
    addNewBlock: bodyAction(
      AddBlockEditorActions.addNewBlock(generateZeckBlockEditorAddBlock),
    ),
    replaceNewBlock: bodyAction(
      AddBlockEditorActions.replaceNewBlock(generateZeckBlockEditorAddBlock),
    ),
  },

  dropDraggedBlock: bodyAction(convertToStateVoid(BodyEditor.dropDraggedBlock)),
};

export default ZeckEditor;
